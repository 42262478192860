import { AssetFilter } from 'modules/analytics/components/AssetFilter/AssetFilter';
import { Section } from 'modules/analytics/components/common/layout/Section/Section';
import { useTranslation } from 'react-i18next';

export const AnalyticsSection = () => {
  const { t } = useTranslation('newHome');

  return (
    <Section
      title={t('content.last7days')}
      subtitle={t('content.subtitle')}
      actionElement={
        <AssetFilter
          appliedFilters={{}}
          onFilter={() => {}}
          trackingProps={{ page: 'new-home' }}
          alignRight
        />
      }
    >
      content here
    </Section>
  );
};
